import React, {useState} from 'react';
import './Contact.css'
import { collection, addDoc } from "firebase/firestore";

function Contact({ firestoreRef, handleToastClick }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMesage] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMesage(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const currentDateTime = new Date();
            //save doc
            const docRef = await addDoc(collection(firestoreRef, "message"), {
                name: name,
                email: email,
                phone: phone,
                message: message,
                date: currentDateTime
            });

            //reset form
            setName('')
            setEmail('')
            setPhone('')
            setMesage('')

            //show toast
            handleToastClick('Your message has been successfully submitted.')

            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    return (
        <div className={"w-full md:h-screen bg-[#FFD5C0] flex flex-col"}>
            <div className={"grow flex justify-center items-center"}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full h-full">
                    <div className={'p-5 md:p-10 flex flex-col justify-center items-center'}>
                        <div
                            className={"sentinel rounded-lg w-full md:w-3/4 lg:w-4/5 max-md:aspect-[15/10] md:aspect-square overflow-hidden relative"}>
                            <div className={'bg-black w-full h-full absolute opacity-75'}></div>
                            <div className={'absolute w-full h-full p-5 md:p-10 flex flex-col justify-center'}>
                                <p className={"w-full text-white text-2xl md:text-5xl md:text-center bevan-regular font-bold"}>Contact Information</p>
                                <div className={'w-full max-md:grow md:mt-10 flex flex-col justify-center'}>
                                    <p className={"text-white md:text-center md:text-lg"}>+44 7466 496 470</p>
                                    <p className={"text-white md:text-center md:text-lg"}>bethelpropertyno1@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'m-5'}>
                        <div className="py-6 h-full flex flex-col justify-center sm:py-12">
                            <div className="relative py-3 w-full md:w-3/4 2xl:w-3/5 sm:mx-auto">
                                <div
                                    className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                                </div>
                                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                                    <div className="max-w-md mx-auto">
                                        <div>
                                            <h1 className="text-2xl font-semibold">Contact Us</h1>
                                        </div>
                                        <div className="divide-y divide-gray-200">
                                            <div
                                                className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                                <div className="relative">
                                                    <input autoComplete="off" id="name" name="name" type="text"
                                                           className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                           placeholder="Name" value={name} onChange={handleNameChange}/>
                                                    <label htmlFor="name"
                                                           className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Name</label>
                                                </div>
                                                <div className="relative">
                                                    <input autoComplete="off" id="email" name="email" type="text"
                                                           className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                           placeholder="Email" value={email} onChange={handleEmailChange}/>
                                                    <label htmlFor="email"
                                                           className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Email</label>
                                                </div>
                                                <div className="relative">
                                                    <input autoComplete="off" id="phone" name="phone" type="text"
                                                           className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                           placeholder="Phone" value={phone} onChange={handlePhoneChange}/>
                                                    <label htmlFor="phone"
                                                           className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Phone</label>
                                                </div>
                                                <div className="relative">
                                                    <input autoComplete="off" id="message" name="message" type="text"
                                                           className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                           placeholder="Message" value={message} onChange={handleMessageChange}/>
                                                    <label htmlFor="message"
                                                           className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Message</label>
                                                </div>
                                                <div className="relative">
                                                    <button className="bg-blue-500 text-white rounded-md px-2 py-1" onClick={handleSubmit}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={'h-14'}/>*/}
        </div>
    );
}

export default Contact;
