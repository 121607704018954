import './Landing.css';
import {useEffect} from "react";

function Landing() {

    useEffect(() => {

        const elts = {
            text1: document.getElementById("text1"),
            text2: document.getElementById("text2")
        };

        const texts = [
            "Seamless property management solutions.",
            "Unlock hassle-free rental experiences.",
            "Reliable rental services tailored to you.",
            "Renting made simple, living made better.",
            "Maximize your property's potential.",
        ];

        const morphTime = 2;
        const cooldownTime = 2.25;

        let textIndex = texts.length - 1;
        let time = new Date();
        let morph = 0;
        let cooldown = cooldownTime;

        elts.text1.textContent = texts[textIndex % texts.length];
        elts.text2.textContent = texts[(textIndex + 1) % texts.length];

        function doMorph() {
            morph -= cooldown;
            cooldown = 0;

            let fraction = morph / morphTime;

            if (fraction > 1) {
                cooldown = cooldownTime;
                fraction = 1;
            }

            setMorph(fraction);
        }

        function setMorph(fraction) {
            elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

            fraction = 1 - fraction;
            elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

            elts.text1.textContent = texts[textIndex % texts.length];
            elts.text2.textContent = texts[(textIndex + 1) % texts.length];
        }

        function doCooldown() {
            morph = 0;

            elts.text2.style.filter = "";
            elts.text2.style.opacity = "100%";

            elts.text1.style.filter = "";
            elts.text1.style.opacity = "0%";
        }

        function animate() {
            requestAnimationFrame(animate);

            let newTime = new Date();
            let shouldIncrementIndex = cooldown > 0;
            let dt = (newTime - time) / 1000;
            time = newTime;

            cooldown -= dt;

            if (cooldown <= 0) {
                if (shouldIncrementIndex) {
                    textIndex++;
                }

                doMorph();
            } else {
                doCooldown();
            }
        }

        animate();
    }, []);

  return (
      <div className={"w-full h-screen"}>
        <div className={'w-full h-full absolute background'}></div>
        <div className="w-full h-full bg-black opacity-75 absolute"/>
        <div className="w-full h-full absolute flex flex-col">
          <div className={"flex justify-center"}>
              <div className={"w-3/4 md:w-1/4 bg-white/85 rounded-lg p-5 my-10"}>
                  <img src={JSON.stringify(require('../assets/logo-transparent.png')).replace(/"/g, '')} className={"object-contain"} alt={'logo'}/>
              </div>
          </div>
            <div className={"message grow flex justify-center items-center p-5 text-2xl md:text-5xl text-white opacity-50 relative"}>
              <span id="text1" className={"absolute text-center m-5"}></span>
              <span id="text2" className={"absolute text-center m-5"}></span>
          </div>
        </div>
      </div>
  );
}

export default Landing;
