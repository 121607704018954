import React, {useEffect, useState} from 'react';
import './Services.css'
import {Slideshow} from "../components/SlideShow";

function Services(props) {

    const [slide, setSlide] = useState(0);
    const [isLandscape, setIsLandscape] = useState(false);

    const nextSlide = () => {
        setSlide((oldCount) => (oldCount + 1) % slides.length)
    };

    //array
    const slides = [
        require('../assets/slides/1.png'),
        require('../assets/slides/2.png'),
        require('../assets/slides/3.png'),
        require('../assets/slides/4.png'),
        require('../assets/slides/5.png'),
    ]

    useEffect(() => {
        const divElement = document.getElementById("video-container");

        const divHeight = divElement.clientHeight;
        const divWidth = divElement.clientWidth;

        if (divWidth > divHeight) {
            setIsLandscape(true)
        } else {
            setIsLandscape(false)
        }

        return () => {

        };
    }, []);

    return (
        <div className={"w-full md:h-screen bg-[#ffe4e1] flex flex-col"}>
            <div className={"text-center px-10 pt-20 text-[#213559] text-5xl sm:text-7xl max-[280px]:text-3xl bevan-regular"}>Our Services</div>
            {isLandscape ? (
                <div className={"grow flex justify-center items-center m-5 p-20"}>
                    <div className={"bg-[#213559] rounded-lg shadow-xl shadow-[#213559] m-5 p-10 aspect-video h-full overflow-hidden relative"}>
                        <Slideshow image={slides[slide]}/>
                        <img id={"base-image"} height={'48px'} width={'48px'} className={"m-10 bottom-0 right-0 absolute"} src={JSON.stringify(require('../assets/arrow-right.png')).replace(/"/g, '')} alt={'arrow'}/>
                        <img id={"ping-animation"} height={'48px'} width={'48px'} className={"m-10 bottom-0 right-0 absolute animate-ping"} src={JSON.stringify(require('../assets/arrow-right.png')).replace(/"/g, '')} onClick={nextSlide} alt={'arrow animation'}/>
                    </div>
                </div>
            ) : (
                <div className={'grid gap-5 grid-cols-1'}>
                    <div className={'w-full aspect-[15/10] p-5'}>
                        <div className={'w-full h-full bg-white rounded-lg relative overflow-hidden'}>
                            <img className={'absolute w-full h-full object-cover'}
                                 src={JSON.stringify(require('../assets/backgrounds/01.jpg')).replace(/"/g, '')} alt={'slide 01'}/>
                            <div className={'absolute w-full h-full bg-black opacity-65'}></div>
                            <div className={'absolute w-full h-full p-5 flex flex-col'}>
                                <p className={'text-white font-bold text-center underline'}>We Rent</p>
                                <p className={'text-white font-semibold text-center grow flex justify-center items-center'}>Our rental offerings cater to a
                                    diverse range of individuals, including single professionals, students, and
                                    families. </p>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full aspect-[15/10] p-5'}>
                        <div className={'w-full h-full bg-white rounded-lg relative overflow-hidden'}>
                            <img className={'absolute w-full h-full object-cover'}
                                 src={JSON.stringify(require('../assets/backgrounds/02.jpg')).replace(/"/g, '')} alt={'slide 02'}/>
                            <div className={'absolute w-full h-full bg-black opacity-65'}></div>
                            <div className={'absolute w-full h-full p-5 flex flex-col'}>
                                <p className={'text-white font-bold text-center underline'}>Diverse Selections</p>
                                <p className={'text-white font-semibold text-center grow flex justify-center items-center'}>We offer properties in different categories such as HMOs, single lets, and houses with one to four bedrooms, ensuring there's something suitable for everyone's needs. </p>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full aspect-[15/10] p-5'}>
                        <div className={'w-full h-full bg-white rounded-lg relative overflow-hidden'}>
                            <img className={'absolute w-full h-full object-cover'}
                                 src={JSON.stringify(require('../assets/backgrounds/03.jpg')).replace(/"/g, '')} alt={'slide 03'}/>
                            <div className={'absolute w-full h-full bg-black opacity-65'}></div>
                            <div className={'absolute w-full h-full p-5 flex flex-col'}>
                                <p className={'text-white font-bold text-center underline'}>Guaranteed Rent</p>
                                <p className={'text-white font-semibold text-center grow flex justify-center items-center'}>We offer long-term rental agreements spanning 1 to 5 years, ensuring guaranteed rent for landlords.   </p>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full aspect-[15/10] p-5'}>
                        <div className={'w-full h-full bg-white rounded-lg relative overflow-hidden'}>
                            <img className={'absolute w-full h-full object-cover'}
                                 src={JSON.stringify(require('../assets/backgrounds/04.jpg')).replace(/"/g, '')} alt={'slide 04'}/>
                            <div className={'absolute w-full h-full bg-black opacity-65'}></div>
                            <div className={'absolute w-full h-full p-5 flex flex-col'}>
                                <p className={'text-white font-bold text-center underline'}>Serviced Accommodation Management</p>
                                <p className={'text-white font-semibold text-center grow flex justify-center items-center'}>For landlords seeking hassle-free property management, our team provides cosmetic maintenance services. </p>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full aspect-[15/10] p-5'}>
                        <div className={'w-full h-full bg-white rounded-lg relative overflow-hidden'}>
                            <img className={'absolute w-full h-full object-cover'}
                                 src={JSON.stringify(require('../assets/backgrounds/05.jpg')).replace(/"/g, '')} alt={'slide 05'}/>
                            <div className={'absolute w-full h-full bg-black opacity-65'}></div>
                            <div className={'absolute w-full h-full p-5 flex flex-col'}>
                                <p className={'text-white font-bold text-center underline'}>Best Tenants </p>
                                <p className={'text-white font-semibold text-center grow flex justify-center items-center'}>We prioritize securing the best tenants for your property. </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Services;
