import React, {useEffect, useRef, useState} from 'react';
import './ForLandlords.css'

function ForLandlords(props) {

    const [restrictVideo, setRestrictVideo] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [videoStatus, setVideoStatus] = useState({});
    const [isHovered, setIsHovered] = useState(false);

    const videoPlayer = useRef(null);

    useEffect(() => {
        // This function will run after every render
        const divElement = document.getElementById("video-container");

        const divHeight = divElement.clientHeight;
        const divWidth = divElement.clientWidth;

        if (divWidth/divHeight > 1.5) {
            setRestrictVideo(true)
        } else {
            setRestrictVideo(false)
        }

        // Return a cleanup function if necessary
        return () => {
            // Cleanup code here (optional)

        };
    }, []);

    useEffect(() => {

        if (!isVideoPlaying) {
            let source = require('../assets/play-button.png');
            setVideoStatus(source)
        } else {
            let source = require('../assets/pause-button.png');
            setVideoStatus(source)
        }

        // Return a cleanup function if necessary
        return () => {
            // Cleanup code here (optional)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVideoPlaying]);

    const togglePlaying = () => {
        // Update the state and pass a callback function
        setIsVideoPlaying(!isVideoPlaying);
        const video = videoPlayer.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className={'w-full h-screen bg-[#ffcdc8] flex flex-col'}>
            <div className={"text-center px-10 pt-20 text-[#213559] text-5xl sm:text-7xl max-[280px]:text-3xl bevan-regular"}>
                For Landlords
            </div>
            <div id={'video-container'} className={'m-5 md:m-10 grow flex justify-center items-center'}>
                {restrictVideo ? (
                    <div className={'h-full max-w-full w-auto aspect-video overflow-hidden rounded-xl relative'}
                         onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={togglePlaying}>
                        <video ref={videoPlayer} className={'object-cover h-full w-full absolute top-0 left-0'}
                               poster={JSON.stringify(require('../assets/Thimbnail.png')).replace(/"/g, '')}>
                            <source src={JSON.stringify(require('../assets/Landlord.mp4')).replace(/"/g, '')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <img id={'video-button'}
                             className={`absolute h-20 w-20 left-2/4 top-2/4 translate-y-[-50%] translate-x-[-50%] transition duration-1000 ease-in-out ${!isVideoPlaying ? 'opacity-100' : `${isHovered ? 'opacity-100' : 'opacity-0'}`}`}
                             src={JSON.stringify(videoStatus).replace(/"/g, '')} alt={'play/pause'}/>
                    </div>
                ) : (
                    <div className={'w-full max-h-full h-auto aspect-video rounded-xl overflow-hidden relative'} onClick={togglePlaying}>
                        <video ref={videoPlayer} className={'absolute'}
                               poster={JSON.stringify(require('../assets/Thimbnail.png')).replace(/"/g, '')}>
                            <source src={JSON.stringify(require('../assets/Landlord.mp4')).replace(/"/g, '')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <img id={'video-button'}
                             className={`absolute h-12 w-12 left-2/4 top-2/4 translate-y-[-50%] translate-x-[-50%] transition duration-1000 ease-in-out ${!isVideoPlaying ? 'opacity-100' : `${isHovered ? 'opacity-100' : 'opacity-0'}`}`}
                             src={JSON.stringify(videoStatus).replace(/"/g, '')} alt={'play/pause'}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ForLandlords;
