import React, {useState} from 'react';
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ForLandlords from "./pages/ForLandlords";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import './App.css'
import Toast from "./components/Toast";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyChS4F4Vj3IVtsr52_Txa6TzV6hBQXSRUc",
    authDomain: "bethel-rental.firebaseapp.com",
    projectId: "bethel-rental",
    storageBucket: "bethel-rental.appspot.com",
    messagingSenderId: "992450335142",
    appId: "1:992450335142:web:690d7e9fdc615a0b993f67",
    measurementId: "G-F6HPH2ZPM4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

function App(props) {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleShowToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000); // Hide toast after 5 seconds
    };

    return (
        <>
            <Landing/>
            <Services/>
            <ForLandlords/>
            <Contact firestoreRef={db} handleToastClick={(param) => handleShowToast(param)}/>
            {/*<div className={'h-14 rounded-t-lg bg-gradient-to-r from-cyan-500 to-blue-500 flex sticky bottom-0 justify-between'}>
                <a className={'mx-5'} href="#">
                    <img className={'w-full h-full px-3 py-4'} src={JSON.stringify(require('../src/assets/facebook.png')).replace(/"/g, '')}/>
                </a>
                <div className={'flex items-center mx-5'}>
                    <span className={'font-semibold text-sm md:text-base text-white'}>Get In Touch: +44 (0) 20 3757 6890</span>
                </div>
            </div>*/}
            <Toast message={toastMessage} show={showToast} setShow={setShowToast} />
        </>
    );
}

export default App;
