const Toast = ({ message, show, setShow }) => {
    return (
        <div className={`fixed bottom-0 right-0 bg-gray-800 text-white p-4 m-4 rounded ${show ? 'block' : 'hidden'}`}>
            <span>{message}</span>
            <button onClick={() => setShow(false)} className="ml-2 text-white">&times;</button>
        </div>
    );
};

export default Toast;
